import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img1 from '../assets/carousel-img/solar.jpg'
import { Link } from 'react-router-dom'

export default function Ges() {
  useEffect(() => {
    document.title = 'Eisytech - GES';
  }, []);
  return (
    <div className='container'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          GES
        </Typography>
        <Typography color="gray" className="font-normal">
          <b>Gücünüzü Güneşten Alın</b> <br />
          Ziraat Bankası faizsiz destekleme kredileriyle Güneş Enerjili
          Tarımsal Sulama Projeleri ve Çatı Tipi (On-Off Grid) Projeleri ile hizmetinizdeyiz. <br />
          Detaylı bilgi ve fiyatlarımız için lütfen bizimle <Link to="/iletisim" className='text-red-600'>iletişime geçin.</Link>
        </Typography>
      </div>
    </div>
  )
}

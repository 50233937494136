import { Card, Typography } from '@material-tailwind/react'
import React from 'react'

export default function TermalKam() {
  return (
    <div className='container mx-auto'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src="https://images.unsplash.com/photo-1485470733090-0aae1788d5af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2717&q=80"
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Lorem, ipsum dolor.
        </Typography>
        <Typography color="gray" className="font-normal">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit reprehenderit blanditiis,
          a impedit officia sequi! Nesciunt quae asperiores eligendi autem, reiciendis quisquam nobis similique,
          unt illum impedit aliquam repellendus, ratione fugiat corporis neque qui quaerat quam velit natus dolorem
          ducimus officiis! Vel a ab porro dolore sequi praesentium nam, nobis cupiditate repellat animi reprehenderit
          veniam id eos debitis quaerat nesciunt voluptas inventore temporibus saepe, doloribus eaque et impedit. Deleniti
          cum, soluta rerum provident vel laudantium eveniet, et obcaecati odit nam praesentium architecto delectus vitae
          animi, hic consequuntur. Assumenda molestias ullam placeat aspernatur aliquam hic, unde soluta dolorum deleniti
          veniam excepturi. Soluta exercitationem, magnam et in, repudiandae sequi voluptatibus provident omnis ab mollitia
          eaque officia, expedita id? Est debitis veritatis aut iste cum cupiditate sunt ut officia ratione maxime
          consequuntur ullam, repellendus deleniti pariatur doloremque impedit porro iure soluta eius saepe qui veniam.
          Rem animi, nobis maiores veniam in dignissimos nemo at dicta asperiores reiciendis, quaerat natus minima
          laudantium molestias eius possimus explicabo, ipsa tempora excepturi eum? Doloribus earum, fuga temporibus
          laudantium possimus excepturi porro autem. Voluptatem aliquam reiciendis unde totam beatae ullam, iure eum
          iste earum dolor consequuntur, illum atque similique animi impedit cumque, ut sed quibusdam quia tempore! Ex,
          unde, ipsam explicabo similique ad cupiditate et, ab deserunt ratione autem placeat consequatur quisquam
          praesentium ullam dolorum dolore? Quas id necessitatibus animi, autem repudiandae perspiciatis amet. Mag
          voluptates laudantium explicabo doloremque reprehenderit! Similique itaque aut dolore doloremque quam.
          Reiciendis suscipit debitis ratione iusto voluptates cum excepturi earum explicabo! Blanditiis id, quam
          b reiciendis odio placeat quidem tempora quod, quasi perspiciatis qui voluptatum iure veritatis dolores
          similique doloribus repudiandae error neque a atque. Laboriosam exercitationem cupiditate libero deleniti in?
          Velit iste, natus soluta eos ex vel corrupti veniam excepturi impedit sunt blanditiis, repellat accusamus culpa,
          veritatis nam eius voluptatem explicabo. Sunt deleniti vero dolor ea laboriosam natus vel suscipit
          necessitatibus quasi enim ducimus, culpa nulla nesciunt laudantium. Quae veritatis debitis, necessitatibus
          quos accusantium, officiis quibusdam maiores dignissimos laudantium mollitia at modi ipsum! Magni illum totam
          laboriosam eius sapiente facere atque tempora. Nihil odit obcaecati provident nostrum architecto accusamus
          officia eligendi numquam ipsam reprehenderit minima pariatur harum dolorum, modi temporibus voluptatibus
          iusto recusandae. Ullam animi qui placeat cupiditate corporis et magni quae deserunt, officia beatae nihil
          suscipit harum iusto possimus, alias ipsam illo dolore consequuntur corrupti eveniet molestiae. Veniam, ipsum
          eveniet. Error, autem explicabo veritatis temporibus quasi provident dolores nihil exercitationem expedita
          magni, beatae mollitia veniam eius facilis in soluta vero cumque, alias tempora fugit commodi aspernatur
          pariatur. Veniam accusamus ducimus, assumenda at facere corporis nobis eius deserunt fuga hic aliquid? In
          sed ea quisquam veritatis amet pariatur rem eveniet eum tenetur facere aperiam, qui, totam possimus suscipit
          culpa deserunt doloribus vitae mollitia. Voluptates vitae accusamus esse aut sint debitis, veritatis facilis
          minima saepe beatae, fugiat cupiditate labore. Voluptate, a. Necessitatibus asperiores velit fugit quaerat
          porro omnis doloremque in molestias ex aliquam ducimus, expedita facilis illum a eum atque pariatur, ad
          delectus! Asperiores quisquam ducimus provident inventore magnam culpa voluptatibus itaque corporis quas
          illo! Doloremque ullam eius quisquam tempore sit ducimus corrupti veniam saepe eligendi. Optio, nostrum
          dolorum, obcaecati odio eum at quod minus quaerat cum porro corporis fugiat, mollitia perspiciatis molestias
          maiores dicta. Sint ut a inventore repellendus dolore recusandae reiciendis autem tempora temporibus dolorib
          us velit culpa perferendis id odit aut delectus veritatis ab, debitis beatae. Itaque at quis assumenda moles
          tiae. Doloremque, minima a beatae corporis cum quas! Amet nam sequi rerum possimus ipsum aliquid impedit vol
          uptates dicta, quos cupiditate quasi at minus dolores? Est, earum aliquam minima expedita quisquam non veri
          tatis similique officia porro nam culpa incidunt a sunt eum, cumque illum eveniet. Consequuntur aliquam al
          ias voluptatum similique id hic corrupti unde, vel, adipisci aliquid inventore, ex repudiandae sit
          delectus nesciunt eius velit vitae fugit modi enim? Atque perspiciatis vitae, dicta ea enim laudantium
          mollitia officiis dolor distinctio eaque labore? Voluptatem aut iusto impedit quidem cupiditate molestiae
          ab eum quasi velit vel? Deserunt, sapiente repellat earum illum, expedita, sunt culpa quod fugit officiis
          omnis aut voluptates excepturi tempore vero suscipit
          ipsa perferendis facilis incidunt? Explicabo ea sit laboriosam eum iusto enim doloribus obcaecati vero.
        </Typography>
      </div>
    </div>
  )
}

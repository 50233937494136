import { motion } from 'framer-motion';
import React from 'react'
import { Link } from 'react-router-dom';
import { fadeIn } from '../variants';


const Cards = (props) => {
    return (
        <motion.div
            variants={fadeIn("up", 0.6)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
        >
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                {/* Kartın içeriği */}
                <div className="relative">
                    {/* Görüntü */}
                    <img
                        src={props.imgUrl} 
                        alt="Görüntü Açıklaması"
                        className="w-full"
                    />
                    {/* Görüntü üzerine yazı */}
                    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/70 p-4">
                        <h2 className="text-2xl text-white font-semibold">{props.title}</h2>
                        <p className="text-white hidden lg:inline">{props.desc}</p>
                        <div className="px-6 py-4">
                            <Link to={props.link} className='p-2 rounded-xl text-white bg-red-600 hover:bg-red-500 items-center'>Detay</Link>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default function HizmetCard() {
    const cardData = [
        {
            id: 1,
            imgUrl: require("../assets/carousel-img/yuksek.jpg"),
            title: "Yüksek Gerilim",
            desc: "Yüksek Gerilim Projelendirme ve Trafo İşletme Sorumluluğu",
            link: "/yuksek-gerilim",
        },
        {
            id: 2,
            imgUrl: require("../assets/carousel-img/solar.jpg"),
            title: "GES",
            desc: "GES Projelendirme ve On Grid Kabul İşlemleri",
            link: "/ges",
        },
        {
            id: 3,
            imgUrl: require("../assets/carousel-img/periyodik.jpg"),
            title: "Elektrik Tesisatı Periyodik kontrol",
            desc: "İç Tesisat Periyodik Kontrol ve Muayenesi",
            link: "/periyodik-kontrol"
        },
       
        {
            id: 4,
            imgUrl: require("../assets/carousel-img/ictesisat.jpg"),
            title: "Elektrik İç Tesisat Proje/Taahhüt",
            desc: "İç Tesisat Proje Hazırlama, Aydınlatma Proje Hazırlama ve Rölave Çıkartma",
            link: "/ic-tesisat"
        },
        {
            id: 5,
            imgUrl: require("../assets/carousel-img/smart.jpeg"),
            title: "Eisytech Smart",
            desc: "Akıllı Tarım Uygulamaları",
            link: "/eisytech-smart"
        },
       
       

    ]
    return (
        <div className=' w-full text-black'>
            <div className="p-4">
                <div>
                    <motion.span
                        variants={fadeIn("up", 0.9)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="absolute text-center left-0 right-0 uppercase text-[38px] lg:text-[70px] font-extrabold -z-5 text-gray-400">
                        hizmetlerimiz
                    </motion.span>
                    <motion.h2
                        variants={fadeIn("down", 0.9)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="relative text-center uppercase font-bold text-[22px] text-gray-800 lg:text-[36px] lg:mt-10 mt-6 mb-6">
                        hizmetlerimiz
                    </motion.h2>
                </div>
            </div>
            <div className="container pl-10 pr-10 pb-10 pt-4">
                <div className='flex flex-wrap justify-center items-center'>
                    {cardData.map((card) => (
                        <div className='flex items-center justify-center w-full md:w-1/2 lg:w-1/3 p-4' key={card.id}>
                            <Cards imgUrl={card.imgUrl} title={card.title} desc={card.desc} link={card.link} />
                        </div>
                    ))}
                </div>


            </div>
        </div>
    )
}

import React, { useState } from "react";
import Logo from "../assets/logo.png"
import {
  Drawer,
  IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import {TbAlignRight} from "react-icons/tb"
import SocialMedia from "./SocialMedia";
export default function DrawerMenu(){
    const [openRight, setOpenRight] = useState(false);
 
    const openDrawerRight = () => setOpenRight(true);
    const closeDrawerRight = () => setOpenRight(false);
 
  return (
    <React.Fragment>
      <Link className="bg-transparent text-red-500 text-[24px] border-none" onClick={openDrawerRight}><TbAlignRight /> </Link>
      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-2 bg-gray-200"
      >
        <div className="mb-6 flex items-center justify-between">
          <img src={Logo} width={"140px"} alt="logo" />
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <div className="flex mb-4">
          <ul>
            <li><Link to="/" className="text-[22px] uppercase text-black hover:text-red-500 font-bold mt-2">ana sayfa</Link></li>
            <li><Link to="/hakkimizda" className="text-[22px] uppercase text-black hover:text-red-500 font-bold mt-2">hakkımızda</Link></li>
            <li><Link to="/hizmetlerimiz" className="text-[22px] uppercase text-black hover:text-red-500 font-bold mt-2">hizmetlerimiz</Link></li>
            <li><Link to="/iletisim" className="text-[22px] uppercase text-black hover:text-red-500 font-bold mt-2">iletişim</Link></li>
            <li><SocialMedia/></li>
          </ul>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
import { Card, Typography } from '@material-tailwind/react'
import React from 'react'
import Img1 from '../assets/carousel-img/eisytech-smart-bg.png'
import Img2 from '../assets/nasilcalisir.jpg'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default function EisytechSmart() {
  useEffect(() => {
    document.title = 'Eisytech Smart - Akıllı Tarım Uygulamaları ve IOT Çözümleri';
  }, []);
  return (
    <div className='container'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Eisytech Smart
        </Typography>
        <Typography color="gray" className="font-normal mb-6">
          <b>Geleceğe Merhaba Deyin</b> <br />
          Eisytech olarak Akıllı Tarım Uygulamalarını önemli buluyoruz. Küresel iklim değişikliği
          ile karşı karşıya olduğumuz bu günlerde doğal kaynaklarımızı etkli kullanmak zorundayız.
          Bunun için de teknolojinin bize sunduğu önemli bir silahımız var, IOT! <br />
          IOT (Internet of Things) yani nesnelerin interneti ile tarım arazilerini internet ile buluşturuyoruz.
          Eisytech <b>Akıllı Tarım Kontrol Sistemi</b>, modüler yapısı sayesinde farklı senaryolara adapte edilebilen 
          çok yönlü bir ekosistem olarak geliştirilmiştir. Alt yapısı geleneksel yöntemlerle hazırlanmış mevcut 
          sulama sistemlerinizi (küresel vana, gübre tankı, su pompası vb.) sistemde herhangi bir değişiklik 
          yapmaya ihtiyaç duymadan eklenti olarak montajı yapılabilen cihazlarımız ile tarım arazilerinizi akıllı hale 
          getirebilirsiniz.
        </Typography>
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="w-full object-cover object-center"
            src={Img2}
          />
        </Card>
        <Typography color="gray" className="font-normal">
        Sisteme çiftçilerimizin istek ve  ihtiyaçlarına uygun olarak sıcaklık, nem, toprak nemi, basınç, debi, rüzgar,  sıvı seviye izleme, 
          otomatik şamandıra, hareket algılama ve dış mekan ip kamera gibi sensörler eklenebilmektedir.
          <br />
          Kısaca, aranizden her türlü sensör verisini alabilir ve mevcut tüm cihazlarınızı internet ile buluşturup 
          mobil uygulamamız üzerinden uzaktan kontrol edebilirsiniz.
          <br /> <br />
          Fiyat bilgisi ve detaylı teknik bilgi almak için bizimle <Link to="/iletisim" className='text-red-700' >iletişme geçin.</Link>
        </Typography>
      </div>
    </div>
  )
}

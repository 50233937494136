import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img1 from '../assets/carousel-img/yuksek.jpg'
import { Link } from 'react-router-dom'

export default function YuksekGerilim() {
  useEffect(() => {
    document.title = 'Eisytech - Yüksek Gerilim';
  }, []);
  return (
    <div className='container'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Yüksek Gerilim Projelendirme ve Trafo İşletme Sorumluluğu
        </Typography>
        <Typography color="gray" className="font-normal">
          <b>Yüksek Gerilim Proje Hazırlama</b> <br />
          Yüksek gerilim projeleri, dikkatli planlama ve hazırlık gerektiren karmaşık ve tehlikeli girişimlerdir. Bu projeler, 1.000 volttan daha yüksek voltajlarda çalışan elektrikli ekipman ve bileşenlerle çalışmayı içerir. Bu nedenle, güvenliğe öncelik vermek ve proje yaşam döngüsü boyunca en iyi uygulamalara bağlı kalmak çok önemlidir
          <br />
          Yüksek gerilim proje hazırlığında ilk adım kapsamlı bir risk değerlendirmesi yapmaktır. Bu, potansiyel tehlikelerin tanımlanmasını ve zarar olasılığının ve ciddiyetinin değerlendirilmesini içerir. Yüksek gerilim projeleriyle ilişkili tehlikeler arasında elektrik çarpması, yanıklar, patlamalar, yangınlar ve daha fazlası yer alabilir. Risk değerlendirmesine bağlı olarak, riskleri azaltmak için uygun kontrol önlemleri alınmalıdır.
          <br />
          Bir sonraki adım, proje kapsamını ve zamanlamasını planlamaktır. Bu, proje hedeflerinin tanımlanmasını, gerekli kaynakların belirlenmesini ve tamamlanma için bir zaman çizelgesinin geliştirilmesini içerir. Proje sırasında ortaya çıkabilecek olası gecikmeleri veya komplikasyonları hesaba katmak ve gerektiğinde beklenmedik durumları oluşturmak önemlidir.
          <br />
          Proje kapsamı ve programı tanımlandıktan sonra, bir sonraki adım gerekli ekipman ve malzemelerin tedarik edilmesidir. Yüksek voltaj projeleri tipik olarak yüksek voltaj ve akım seviyelerine dayanacak şekilde tasarlanmış özel ekipman gerektirir. Bu, transformatörleri, devre kesicileri, kabloları ve diğer bileşenleri içerebilir. Tüm ekipman ve malzemelerin uygun şekilde derecelendirildiğinden ve yüksek gerilim uygulamalarında kullanım için onaylandığından emin olmak önemlidir
          <br />
          Yüksek gerilim proje hazırlama, dikkatli planlama ve uygulama gerektiren karmaşık ve zorlu bir süreçtir. Güvenliğe öncelik vererek, en iyi uygulamalara bağlı kalarak ve deneyimli profesyonellerle çalışarak yüksek gerilim projeleri başarıyla ve kazasız tamamlanabilir.
          <br /> <br />
          <b>Yüksek Gerilim Trafo İşletme Sorumluluğu</b> <br />
          24246 sayılı resmi gazete “Elektrik Kuvvetli Akım Tesisleri Yönetmeliği” ve Elektrik Mühendisleri Odasının (EMO) yayınladığı “Elektrik Yüksek Gerilim Tesisleri İşletme Sorumluluğu Yönetmeliği” kapsamında elektrik yüksek gerilim sistemlerine sahip, kendine ait trafo merkezi olan işletmelerin can ve mal güvenliğinin sağlanması, ekonomik kayıpların minimize edilmesi için gerekli hizmetlerin yürütülmesini üstlenen ve kontrol edilmesini sağlayan elektrik mühendisinin almış olduğu göreve “Tesis İşletme Sorumluluğu” veya “Trafo İşletme Sorumluluğu” denir.
          <br />
          <b><i>İşletmeler sistemin kabulünden sonra ve enerji altında olduğu sürece Tesis İşletme Sorumlusu bulundurmak zorundadır.</i></b>
          <br />
          Detaylı bilgi ve fiyatlarımız için lütfen bizimle <Link to="/iletisim" className='text-red-600' >iletişime geçin.</Link>
        </Typography>
      </div>
    </div>
  )
}

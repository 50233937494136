import { Carousel, Typography } from "@material-tailwind/react";
import Img1 from "../assets/carousel-img/yuksek.jpg"
import Img2 from "../assets/carousel-img/periyodik.jpg"
import Img3 from "../assets/carousel-img/ictesisat.jpg"
import Img4 from "../assets/carousel-img/solar.jpg"
import Img5 from "../assets/carousel-img/eisytech-smart-bg.png"
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

export default function CarouselSlider() {
    return (
        <Carousel className="rounded-xl z-0">
            <div className="relative h-full w-full">
                <img
                    src={Img5}
                    alt="img-5"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 object-cover overflow-hidden">
                    <motion.div
                        variants={fadeIn("up", 0.4)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-[17px] md:text-3xl lg:text-5xl"
                        >
                            EISYTECH SMART
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-8 opacity-80 lg:inline uppercase hidden text-[17px] lg:text-lg"
                        >
                            eısytech akıllı tarım uygulamaları

                        </Typography>
                        <div className="flex justify-center gap-2 mb-2 text-[7px] lg:text-[17px]">
                            <Link to="/eisytech-smart" className="rounded-3xl bg-white p-2 items-center text-black hover:bg-red-700 hover:text-white">
                                Daha Fazla
                            </Link>
                            <Link to="/iletisim" className=" p-2 rounded-3xl bg-transparent text-white hover:bg-red-700/70">
                                İletişime Geçin
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={Img1}
                    alt="img-1"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 object-cover overflow-hidden">
                    <motion.div
                        variants={fadeIn("up", 0.4)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-[17px] md:text-3xl lg:text-5xl"
                        >
                            Güçle Tanışın
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-8 opacity-80 lg:inline hidden text-[17px] lg:text-lg"
                        >
                            Yüksek Gerilim Projelendirme ve Trafo İşletme Sorumluluğu

                        </Typography>
                        <div className="flex justify-center gap-2 mb-2 text-[7px] lg:text-[17px]">
                            <Link to="/yuksek-gerilim" className="rounded-3xl bg-white p-2 items-center text-black hover:bg-red-700 hover:text-white">
                                Daha Fazla
                            </Link>
                            <Link to="/iletisim" className=" p-2 rounded-3xl bg-transparent text-white hover:bg-red-700/70">
                                İletişime Geçin
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={Img2}
                    alt="img-2"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 object-cover overflow-hidden">
                    <motion.div
                        variants={fadeIn("up", 0.4)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-[17px] md:text-3xl lg:text-5xl"
                        >
                            Periyodik Kontrol
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-8 opacity-80 lg:inline uppercase hidden text-[17px] lg:text-lg"
                        >
                           elektrik iç tesisatı periyodik kontrolleri

                        </Typography>
                        <div className="flex justify-center gap-2 mb-2 text-[7px] lg:text-[17px]">
                            <Link to="/periyodik-kontrol" className="rounded-3xl bg-white p-2 items-center text-black hover:bg-red-700 hover:text-white">
                                Daha Fazla
                            </Link>
                            <Link to="/iletisim" className=" p-2 rounded-3xl bg-transparent text-white hover:bg-red-700/70">
                                İletişime Geçin
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={Img3}
                    alt="img-1"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 object-cover overflow-hidden">
                    <motion.div
                        variants={fadeIn("up", 0.4)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-[10px] md:text-3xl lg:text-5xl"
                        >
                            Elektrik İç Tesisat Proje Hazırlama ve Taahhüt
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-8 opacity-80 lg:inline hidden text-[17px] lg:text-lg"
                        >
                            İç Tesisat Proje Hazırlama, Aydınlatma Proje Hazırlama ve Rölave Çıkarma

                        </Typography>
                        <div className="flex justify-center gap-2 mb-2 text-[7px] lg:text-[17px]">
                            <Link to="/ic-tesisat" className="rounded-3xl bg-white p-2 items-center text-black hover:bg-red-700 hover:text-white">
                                Daha Fazla
                            </Link>
                            <Link to="/iletisim" className=" p-2 rounded-3xl bg-transparent text-white hover:bg-red-700/70">
                                İletişime Geçin
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={Img4}
                    alt="img-4"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 object-cover overflow-hidden">
                    <motion.div
                        variants={fadeIn("up", 0.4)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-[17px] md:text-3xl lg:text-5xl"
                        >
                            GES
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-8 opacity-80 lg:inline uppercase hidden text-[17px] lg:text-lg"
                        >
                            GES Projelendirme Kurulum ve On-Grid kabul İşlemleri

                        </Typography>
                        <div className="flex justify-center gap-2 mb-2 text-[7px] lg:text-[17px]">
                            <Link to="/ges" className="rounded-3xl bg-white p-2 items-center text-black hover:bg-red-700 hover:text-white">
                                Daha Fazla
                            </Link>
                            <Link to="/iletisim" className=" p-2 rounded-3xl bg-transparent text-white hover:bg-red-700/70">
                                İletişime Geçin
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Carousel>
    );
}
import React from 'react'
import DrawerMenu from './DrawerMenu'
import Logo from "../assets/logo.png"
import { Link } from 'react-router-dom'
export default function Navbar() {
  return (
    <div className='flex sticky top-0 z-50 items-center justify-between mb-4 px-1 bg-white border-b-1 border-b-red-500 shadow-sm shadow-red-400'>
        <div className="logo">
            <Link to="/">
                <img src={Logo} width={"140px"} alt="logo" />
            </Link>
        </div>
        <DrawerMenu/>
    </div>
  )
}

import React, { useEffect } from 'react'
import HizmetCard from '../components/HizmetCard'

export default function Hizmetlerimiz() {
  useEffect(() => {
    document.title = 'Eisytech - Hizmetlerimiz';
  }, []);
  return (
    <div className='w-full'>
      <HizmetCard className="mt-0" />
    </div>
  )
}

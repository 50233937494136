import React from 'react'
import { Link } from 'react-router-dom'
import {FaWhatsapp, FaInstagram, FaAt} from "react-icons/fa"

export default function SocialMedia() {
  return (
    <div className='container mx-auto'>
        <div className="flex justify-center items-center mt-10 mb-10 gap-10 ">
            <Link to="https://wa.me/+905382052436"><FaWhatsapp title='whatsapp' className='bg-green-700 text-white hover:shadow-lg rounded-xl text-[22px] hover:text-[27px] transition-all'/></Link>
            <Link to="https://www.instagram.com/eisytech/"><FaInstagram title='instagram' className='bg-pink-600 text-white  hover:shadow-lg rounded-lg text-[22px] hover:text-[27px] transition-all'/></Link>
            <Link to="/" title='x/twitter' className='btn hover:shadow-lg rounded-md text-[22px]  p-1 hover:text-[27px] transition-all'>X</Link>
            <Link to="mailto:info@eisytech.com"><FaAt title='e-posta' className='bg-white text-red-800 hover:shadow-lg rounded-xl text-[22px] hover:text-[27px] transition-all'/></Link>
        </div>
    </div>
  )
}

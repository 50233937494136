import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img1 from '../assets/yildirim.jpg'
import { Link } from 'react-router-dom'

export default function Yildirim() {
  useEffect(() => {
    document.title = 'Eisytech - Yıldırımdan Korunma';
  }, []);
  return (
    <div className='container mx-auto'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="yildirimdan-koruma"
            className="h-[32rem] w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Yıldırımdan Korunma Tesisatı Periyodik Kontrol
        </Typography>
        <Typography color="gray" className="font-normal">
        <p>Yıldırımdan korunma, binaları, yapıları ve insanları yıldırım çarpmalarının zararlı etkilerinden korumak için tasarlanmış bir sistemdir. Yıldırım, binalara ve yapılara önemli ölçüde zarar verebilen ve insanların güvenliği için ciddi risk oluşturan bir doğa olayıdır.</p>
          <br />
          <p>Bir yıldırımdan korunma sistemi tipik olarak aşağıdakiler de dahil olmak üzere birkaç bileşenden oluşur:</p>
          <ul className='list-decimal pl-4'>
            <li>Paratonerler: Hava terminalleri olarak da bilinen, bir binanın veya yapının en yüksek noktasına monte edilen metal çubuklardır. Yıldırım çarpmalarını çekmek ve elektrik akımının takip etmesi için bir yol sağlamak üzere tasarlanmıştır.</li>
            <li>İniş iletkenleri: Paratonerleri topraklama sistemine bağlayan metal kablolardır. Elektrik akımının takip etmesi için düşük dirençli bir yol sağlarlar, bu da binanın veya yapının hasar görmesini önlemeye yardımcı olur.</li>
            <li>Topraklama sistemi: Bu, bina veya yapının yakınında toprağa gömülü metal çubuklardan veya plakalardan oluşur. Topraklama sistemi, elektrik akımının toprağa akması için düşük dirençli bir yol sağlar.</li>
            <li>Aşırı gerilim koruma cihazları: Elektrik ve haberleşme sistemlerini yıldırım düşmesi sonucu oluşan gerilim dalgalanmalarından korumak için kurulan cihazlardır.</li>
            <li> Bağlama: Bu, aynı elektrik potansiyelinde olmalarını sağlamak için binanın veya yapının tüm metal bileşenlerinin topraklama sistemine bağlanmasını içerir.</li>
          </ul>
          <br />
          <p>
          Düzgün bir şekilde kurulmuş bir yıldırımdan korunma sistemi, binaların ve yapıların hasar görmesini önlemeye ve yıldırım çarpmalarının neden olduğu yaralanma veya ölüm riskini azaltmaya yardımcı olabilir. Yıldırımdan korunma sistemleri kalifiye ve deneyimli profesyoneller tarafından kurulmalı, ulusal ve uluslararası standart ve yönetmeliklere uygun olmalıdır. Sistemin etkili kalmasını sağlamak için düzenli bakım ve testler de yapılmalıdır.
          </p>
          <br />
          Detaylı bilgi ve fiyatlarımız için lütfen bizimle <Link to="/iletisim" className='text-red-600'>iletişime geçin.</Link>
        </Typography>
      </div>
    </div>
  )
}

import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img1 from '../assets/carousel-img/ictesisat.jpg'
import { Link } from 'react-router-dom'

export default function IcTesisat() {
  useEffect(() => {
    document.title = 'Eisytech - Elektrik İç Tesisat Proje Hazırlama ve Taahhüt';
  }, []);
  return (
    <div className='container'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Elektrik İç Tesisat Proje Hazırlama ve Taahhüt
        </Typography>
        <ul color="gray" className="font-normal list-disc">
          <li>Yapının kullanımına ilişkin gerekli ihtiyaçların yerel ve uluslararası standartlara uygun olarak belirlenmesi,</li>
          <li>Proje raporlarının hazırlanarak avan projelerin ve prensip şemalarının hazırlanması,</li>
          <li>Trafo, jeneratör, AG ve YG odaları yerleşimlerinin optimum ve tekniğine uygun yapılarak, bina içi dağıtımlarda kullanılacak güzergah ve şaftların belirlenmesi,</li>
          <li>Aydınlatma, kablo kesit ve akım kontrolü hesaplarının yapılması,</li>
          <li>Yangın algılama sistemleri, telefon, data, yapısal kablolama gibi zayıf akım sistem projelerinin bina ihtiyacına göre tasarlanması,</li>
          <li>Dekorasyon projeleri çerçevesinde uygulama projelerinin hazırlanması,</li>
          <li>Kuvvetli akım ve zayıf akım sistemlerinin diğer disiplinler ile beraber koordinasyon paftalarının hazırlanması,</li>
          <li>Pano yükleme cetvellerinin ve imalat resimlerinin hazırlanması,</li>
          <li>Çevre aydınlatma projelerinin hazırlanması,</li>
          <li>Topraklama ve yıldırımdan korunma hesaplarının yapılarak projelerin çizilmesi,</li>
          <li>Teknik şartname ve ihale keşiflerinin oluşturulması, hazırlanan projelerin yerel ve resmi makam onaylarının alınması,</li>
          <li>As-built planlarının hazırlanması,</li>
          <li>Shop drawing planlarının hazırlanması,</li>
          <li>İşletme ve bakım şartnamelerinin hazırlanması.</li>
        </ul>
        <p>Detaylı bilgi ve fiyat bilgisi almak için lütfen bizimle <Link className='text-red-600' to="/iletisim" >iletişime geçin.</Link></p>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
export default function Hakkimizda() {
    useEffect(() => {
        document.title = 'Eisytech - Hakkımızda';
      }, []);
    return (
        <div className='container'>
            <div>
                <motion.span
                    variants={fadeIn("down", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className="absolute text-center left-0 right-0 uppercase text-[38px] lg:text-[70px] font-extrabold -z-5 text-gray-400">
                    hakkımızda
                </motion.span>
                <motion.h2
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className="relative text-center uppercase font-bold text-[22px] text-gray-800 lg:text-[36px] lg:mt-10 mt-6 mb-6">
                    hakkımızda
                </motion.h2>
            </div>
            <div className='text-justify mt-10'>
                <motion.p
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='mb-4 font-semibold text-[21px]'
                >
                    Elektrik Proje Taahhüt, Yüksek Gerilim İşletme Sorumluluğu, İş Ekipmanlarının Periyodik Kontrolü ve Muayenesi alanlarında yetkin kadrosuyla faaliyet gösteren Eisytech, iş sağlığı ve güvenliği ilkelerine önem veren; şahıslara/firmalara güncel yönetmelikler ve standartlar ışığında hızlı, güvenilir ve kaliteli hizmet vermeyi amaçlamaktadır.
                </motion.p>
                <motion.p
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='mb-4 font-normal text-[18px]'
                >
                    Eisytech'te teknolojinin sorunları çözmek için nasıl kullanılabileceği konusunda tutkuluyuz. Bu tutku, bizi sürekli olarak elektrik mühendisliği alanında mümkün olanın sınırlarını zorlamaya itti. İnovasyona olan bağlılığımız, hem güvenilir hem de enerji açısından verimli olan son teknoloji çözümlerin geliştirilmesine yol açmıştır.
                </motion.p>
                <motion.p
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='mb-4 font-normal text-[18px]'
                >
                    Başarının anahtarının müşteri odaklı bir yaklaşım olduğuna inanıyoruz. Bu nedenle yaptığımız her işte müşteri memnuniyetini ön planda tutuyoruz. Müşterilerimizin ihtiyaçlarını karşılayan özel çözümler sunarak onlarla uzun süreli ilişkiler kurmaya çalışıyoruz. Kaliteye, güvenilirliğe ve müşteri memnuniyetine olan bağlılığımız bizi rakiplerimizden ayırır.
                </motion.p>
                <motion.p
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='mb-4 font-normal text-[18px]'
                >
                    Alanlarında uzaman ekimiz ile, her projenin zamanında ve en yüksek standartlarda tamamlanmasını sağlamak için müşterilerimizle yakın işbirliği içerisinde çalışırız.
                </motion.p>
            </div>
        </div>
    )
}

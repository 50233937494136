import React, { useEffect } from 'react'
import CarouselSlider from '../components/CarouselSlider'
import SocialMedia from '../components/SocialMedia'
import TrialVideo from '../components/TrialVideo'


export default function Home() {
  useEffect(() => {
    document.title = 'Eisytech - Ana Sayfa';
  }, []);
  return (
    <div className='p-4 mx-auto'>
      <CarouselSlider />
      <TrialVideo/>
      <span className='mt-10'>
       <SocialMedia/>
      </span>
    </div>
  )
}

import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img1 from '../assets/carousel-img/periyodik.jpg'
import { Link } from 'react-router-dom'

const Cards = (props) =>{
  return (
    <Card className='w-auto h-48 bg-gray-600 text-white p-6 rounded-lg justify-between border-1'>
      <Typography variant='h4' className='lg:text-[17px]'>{props.title}</Typography>
      <Link to={props.link} className='btn bg-white text-red-600 font-bold p-2 text-center rounded-lg'>Detay</Link>
    </Card>
  )
}

export default function Periyodik() {
  useEffect(() => {
    document.title = 'Eisytech - Elektrik Tesisatı Periyodik Kontrolleri ';
  }, []);
  const cardData = [
    {id:1, title:"İç Tesisat Periyodik Kontrol", link:"/ic-tesisat-periyodik-kontrol"},
    {id:2, title:"Topraklama Ölçümleri", link:"/topraklama-olcumleri"},
    {id:3, title:"Yıldırımdan Korunma Tesisatı Periyodik Kontrol", link:"/yildirimdan-korunma"},
    {id:4, title:"Yalıtım İzolayson Testleri", link:"/periyodik-kontrol"},
    {id:5, title:"Termal Kamera İle Ölçüm", link:"/periyodik-kontrol"},
    {id:6, title:"Jeneratör Periyodik Kontrol", link:"/periyodik-kontrol"},
  ]
  return (
    <div className='container'>
        <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Img1}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
         Elektrik Tesisatı Periyodik Kontrolleri
        </Typography>
        <Typography color="gray" className="font-normal">
          Elektrik iç tesisatlarının periyodik kontrolü ve muayenesi, tesisatın güvenliğini, uyumluluğunu, verimliliğini ve uzun ömürlülüğünü sağlamak için çok önemlidir. İç Tesisat Kontrolleri/Muayenesi İş Ekipmanlarının Kullanımında Sağlık ve Güvenlik Şartları Yönetmeliğinde belirtildiği gibi iç tesisat kontrollerinin standartta aksi belirtilmedikçe yılda bir (1) defa periyodik kontrolü gerekmektedir.
        </Typography>
        <div className='p-2 mb-4 mt-8'>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-6">
            {cardData.map((card)=>(
              <Cards key={card.id} title={card.title} link={card.link} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

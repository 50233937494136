import { Card, CardBody, CardHeader } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import { MdLocationOn, MdMail, MdPhoneAndroid } from 'react-icons/md';
import { motion } from "framer-motion"
import { fadeIn } from "../variants"
const Cards = (props) => {
    useEffect(() => {
        document.title = 'Eisytech - İletişim';
      }, []);
    return (
        <motion.div
            variants={fadeIn("up", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
        >
            <Card
                className="mt-6 w-80 h-60 items-center p-6 rounded-lg shadow-lg shadow-gray-800 bg-white text-black  hover:shadow-red-900">
                <CardHeader className='bg-transparent border-0'> <span className='text-center z-10 font-bold text-[40px] text-red-600'>{props.icon}</span></CardHeader>
                <CardBody>
                    <h5 className='text-center text-[27px] font-bold uppercase'>{props.title}</h5>
                    <a href={props.link} className='text-center items-center'>{props.desc}</a>
                </CardBody>
            </Card>
        </motion.div>

    )
}

export default function Iletisim() {
    const cardData = [
        {
            id: 1,
            icon: <MdLocationOn />,
            title: "Adres",
            link: "https://www.google.com/maps/place/Eisytech+Periyodik+Kontrol+ve+Muayene/@36.644957,33.438045,14z/data=!4m6!3m5!1s0x14d96deb20897957:0x3c3ffa69a6da8d42!8m2!3d36.6449569!4d33.4380452!16s%2Fg%2F11tjq4rz3h?hl=tr&entry=ttu",
            desc: "Pınarbaşı Mahallesi, Ali Osman Cihan İş Hanı No 23/402 33600 Mut/Mersin"
        },
        {
            id: 2,
            icon: <MdMail />,
            title: "E-Posta",
            link: "mailto:info@eisytech.com",
            desc: "info@eisytech.com"
        },
        {
            id: 3,
            icon: <MdPhoneAndroid />,
            title: "Telefon",
            link: "tel:+905382052436",
            desc: "+905382052436"
        }
    ]
    return (
        <div className='container'>
            <div>
                <motion.span
                    variants={fadeIn("down", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className="absolute text-center left-0 right-0 uppercase text-[38px] lg:text-[70px] font-extrabold -z-5 text-gray-400">
                    iletişim
                </motion.span>
                <motion.h2
                    variants={fadeIn("up", 0.9)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className="relative text-center uppercase font-bold text-[22px] text-gray-800 lg:text-[36px] lg:mt-10 mt-6 mb-6">
                    iletişim
                </motion.h2>
            </div>

            <div className="flex flex-wrap justify-center items-center gap-x-8 gap-y-8 mt-12 mb-8">
                {cardData.map((card) => (
                    <Cards
                        key={card.id}
                        icon={card.icon}
                        title={card.title}
                        link={card.link}
                        desc={card.desc}
                    />
                ))}
            </div>
        </div>
    )
}

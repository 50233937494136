import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Hakkimizda from './pages/Hakkimizda';
import Hizmetlerimiz from './pages/Hizmetlerimiz'
import Iletisim from './pages/Iletisim'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Lisans from './pages/Lisans';
import YuksekGerilim from './pages/YuksekGerilim';
import Periyodik from './pages/Periyodik';
import IcTesisat from './pages/IcTesisat';
import Ges from './pages/Ges';
import EisytechSmart from './pages/EisytechSmart'
import IcTesisatPer from './pages/IcTesisatPer'
import Topraklama from './pages/Topraklama'
import Yalitim from './pages/Yalitim'
import Jenarator from './pages/Jenarator'
import TermalKam from './pages/TermalKam'
import Yildirim from './pages/Yildirim'

export default function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path='/'>
        <Route index element={<Home/>} />
        <Route path='hakkimizda' element={<Hakkimizda />} />
        <Route path='hizmetlerimiz' element={<Hizmetlerimiz />} />
        <Route path='iletisim' element={<Iletisim />} />
        <Route path='lisans' element={<Lisans/>} />
        <Route path='yuksek-gerilim' element={<YuksekGerilim/>} />
        <Route path='periyodik-kontrol' element={<Periyodik/>} />
        <Route path='ic-tesisat' element={<IcTesisat/>} />
        <Route path='ges' element={<Ges/>} />
        <Route path='eisytech-smart' element={<EisytechSmart/>} />
        <Route path='ic-tesisat-periyodik-kontrol' element={<IcTesisatPer/>} />
        <Route path='topraklama-olcumleri' element={<Topraklama />} />
        <Route path='yalitim-testleri' element={<Yalitim/>} />
        <Route path='termal-kamera-ile-olcum' element={<TermalKam/>} />
        <Route path='jenerator-periyodik-kontrol' element={<Jenarator/>} />
        <Route path='yildirimdan-korunma' element={<Yildirim/>} />
      </Route>
    </Routes>
    <Footer/>
    </BrowserRouter>
  )
}

import { Card, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import Img2 from '../assets/topraklama.jpeg'
import { Link } from 'react-router-dom'

export default function Topraklama() {
  useEffect(() => {
    document.title = 'Eisytech - Topraklama Ölçümleri';
  }, []);
  return (
    <div className='container mx-auto'>
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Img2}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Topraklama Ölçümleri
        </Typography>
        <Typography color="gray" className="font-normal">
        Topraklama ölçümü, bir elektrik tesisatındaki topraklama sisteminin etkinliğini değerlendirme sürecidir. Topraklama sistemi, bir arıza durumunda arıza akımının akması için güvenli bir yol sağlamak ve böylece insanları ve ekipmanı elektrik tehlikelerinden korumak üzere tasarlanmıştır.
        <ul className='pl-4 list-decimal'>
            <li>Toprak direnci ölçümü: Bu, topraklama elektrotunun toprağa direncinin ölçülmesini içerir. Düşük direnç değeri iyi topraklamayı gösterirken, yüksek direnç değeri zayıf topraklamayı gösterir.</li>
            <li>Süreklilik testi: Bu, tüm metal bileşenlerin ve bağlantıların bağlanması da dahil olmak üzere topraklama sisteminin sürekliliğinin ölçülmesini içerir. Bu, tüm bileşenlerin düzgün bir şekilde bağlanmasını ve topraklama sisteminde herhangi bir kesinti veya kesinti olmamasını sağlar.</li>
            <li> Voltaj ölçümü: Bu, topraklama elektrodu ile uzak bir toprak referans noktası arasındaki voltajın ölçülmesini içerir. Düşük voltaj değeri iyi topraklamayı, yüksek voltaj değeri ise zayıf topraklamayı gösterir.</li>
            <li>Akım enjeksiyon testi: Bu, topraklama sistemine bir test akımı enjekte etmeyi ve ortaya çıkan voltaj düşüşünü ölçmeyi içerir. Bu yöntem, topraklama sistemindeki yüksek dirençli bağlantıları veya diğer arızaları belirlemek için kullanılır.</li>
          </ul>
          <br />
          Detaylı bilgi ve fiyatlarımız için lütfen bizimle <Link to="/iletisim" className='text-red-600'>iletişime geçin.</Link>
        </Typography>
        
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'

export default function Lisans() {
    useEffect(() => {
        document.title = 'Eisytech - Lisans';
      }, []);
    return (
        <div className='container'>
            <div className="lisans-text mb-6 mt-6 text-justify p-4 text-[16px]">
                <h2 className="text-center mb-2 font-bold">Telif Hakkı ve Kullanım İzni Bildirgesi</h2>
                <h3 className='mb-2'>Telif Hakkı Bildirgesi:</h3>
                <p className='mb-2'>
                    Bu web sitesinin tüm içeriği, Eisytech veya ilgili telif hakkı sahibinin mülkiyetindedir ve telif hakkı kanunlarına tabidir.
                    Bu web sitesinin tüm içeriği, yazılı, görsel ve sesli malzemeleri kapsar ve izinsiz kullanımı yasaktır.
                </p>
                <h3 className="mb-2">Kullanım İzni:</h3>
                <p className="mb-2">
                    Bu web sitesi ve içeriği, aşağıdaki şartlar dahilinde kullanılabilir:
                    <span>
                        <ul className='mb-2 ml-4 pl-2 list-decimal'>
                            <li className="mb-1">Kişisel Kullanım: Web sitemizi kişisel amaçlarla ziyaret edebilir ve içeriği kişisel kullanımınız için görüntüleyebilirsiniz.</li>
                            <li className="mb-1">Referans Amaçlı Kullanım: Web sitemizin içeriğine referans vermek amacıyla makaleler, blog gönderileri veya benzeri materyallerde kısa alıntılar yapabilirsiniz. Ancak, alıntılar, içeriğimizin kökenini ve kaynağını belirten açık bir atıf içermelidir.</li>
                            <li className="mb-1">İzinle Kullanım: Ticari veya diğer herhangi bir kullanım için izin almak için İletişim Bilgileri bölümünden bize başvurmalısınız.</li>
                        </ul>
                    </span>
                </p>
                <h3 className="mb-2">Kısıtlamalar:</h3>
                <p className="mb-2">
                    Bu web sitesinin içeriği aşağıdaki şartlarda kullanılamaz:
                    <span>
                        <ul className='mb-2 ml-4 pl-2 list-decimal'>
                            <li className="mb-1">Telif Hakkı İhlali: Web sitemizin içeriğini izin almadan kopyalayamaz, dağıtamaz veya yeniden üretemezsiniz. </li>
                            <li className="mb-1"> Türev İşler: Web sitemizin içeriğini temel alarak türev işler oluşturamazsınız.</li>
                            <li className="mb-1"> Yasal İhlal: Web sitemizin içeriği yasa dışı amaçlarla kullanılamaz.</li>
                        </ul>
                    </span>
                </p>
                <h3 className='mb-2'>Telif Hakkı İhlali İçin Bildirim:</h3>
                <p className="mb-2">
                    Telif hakkı ihlali konusunda endişeniz varsa, lütfen "İletişim Bilgileri" bölümünden bize ulaşın ve konuyla ilgili gerekli adımları atacağız.
                    <br />
                    Bu lisans metni, 20 Ekim 2023 tarihinden itibaren geçerli olacak şekilde güncellenmiştir ve değişiklikler web sitemizde ilan edildiğinde yürürlüğe girecektir.
                </p>
                <h3 className="font-bold mb-2">İletişim Bilgileri:</h3>
                <span className='mb-2'>
                    <ul className='mb-2 ml-4 list-disc'>
                        <li className="mb-1">Eisytech</li>
                        <li className="mb-1">Pınarbaşı Mahallesi Ali Osman Cihan İş Hanı No 23/402 33600 Mut/Mersin</li>
                        <li className="mb-1">+905382052436</li>
                        <li className="mb-1">info@eisytech.com</li>
                    </ul>
                    <p>Son güncelleme tarihi: 17 Ekim 2023</p>
                </span>
            </div>
        </div>
    )
}

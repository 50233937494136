import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Logo from '../assets/logo.png'
export default function Footer() {
  return (
    <footer className="w-full bg-white p-8 mt-16 bottom-0 right-0 left-0 border-t-2 border-gray-700">
      <div className="flex  lg:flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
        <img src={Logo} alt="logo-ct" className=" w-72" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <Link
              to="/hakkimizda"
              color="blue-gray"
              className="font-normal transition-colors hover:text-red-700 focus:text-red-700 hover:border-b hover:border-b-red-600 transition-transform-4"
            >
              Hakkımızda
            </Link>
          </li>
          <li>
            <Link
              to="/lisans"
              color="blue-gray"
              className="font-normal transition-colors hover:text-red-700 focus:text-red-700 hover:border-b hover:border-b-red-600 transition-transform-4"
            >
              Lisans
            </Link>
          </li>
          <li>
            <Link
              as="a"
              to="/hizmetlerimiz"
              color="blue-gray"
              className="font-normal transition-colors hover:text-red-700 focus:text-red-700 hover:border-b hover:border-b-red-600 transition-transform-4"
            >
              Hizmetlerimiz
            </Link>
          </li>
          <li>
            <Link
              as="a"
              to="/iletisim"
              color="blue-gray"
              className="font-normal transition-colors hover:text-red-700 focus:text-red-700 hover:border-b hover:border-b-red-600 transition-transform-4"
            >
              İletişim
            </Link>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-blue-gray-50" />
      <Typography color="blue-gray" className="text-center font-normal">
        &copy; 2023 <a href="http://www.tunahantatli.com">Tunahan Tatlı</a>
      </Typography>
    </footer>
  );
}
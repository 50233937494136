import React from 'react'
import Trial from '../assets/trial.mp4'

export default function TrialVideo() {
  return (
    <div className='flex justify-center items-center w-full mt-10'>
        <video autoPlay muted loop className='object-cover rounded-lg'>
            <source src={Trial} className='object-cover' />
        </video>
    </div>
  )
}
